import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { Extras } from '@app/components/organisms/LegEditorForm/LegFieldGroup/LegFieldGroup'

import { getFormattedPrice } from '@shared/utils/offer.utils'

import {
  getLegFlightTimeProfit,
  getLegVariableCost,
} from '@shared/utils/price.utils'
import { useSelector } from 'react-redux'
import { selectSelectedOperator } from '@app/store/core/userOperators/userOperators.selectors'

type LegPriceTooltipProps = {
  extras?: Extras | null
}

const LegPriceTooltip = ({ extras }: LegPriceTooltipProps) => {
  const { t } = useTranslation()

  const selectedOperator = useSelector(selectSelectedOperator)

  if (!extras) {
    return null
  }

  return (
    <Container>
      <PriceContainer>
        <PriceLabel>
          {t('molecules.LegPriceTooltip.variableHourlyCost')}
        </PriceLabel>
        {getFormattedPrice(
          getLegVariableCost(extras),
          selectedOperator?.currency?.symbol || '',
        )}
      </PriceContainer>

      <PriceContainer>
        <PriceLabel>{t('molecules.LegPriceTooltip.fuelCost')}</PriceLabel>
        {getFormattedPrice(
          extras.fuel_cost,
          selectedOperator?.currency?.symbol || '',
        )}
      </PriceContainer>

      <PriceContainer>
        <PriceLabel>
          {t('molecules.LegPriceTooltip.flightTimeProfit')}
        </PriceLabel>
        {getFormattedPrice(
          getLegFlightTimeProfit(extras),
          selectedOperator?.currency?.symbol || '',
        )}
      </PriceContainer>

      <PriceContainer>
        <PriceLabel>{t('molecules.LegPriceTooltip.airportFee')}</PriceLabel>
        {getFormattedPrice(
          extras.airport_fee,
          selectedOperator?.currency?.symbol || '',
        )}
      </PriceContainer>

      <PriceContainer>
        <PriceLabel>{t('molecules.LegPriceTooltip.handlingFee')}</PriceLabel>
        {getFormattedPrice(
          extras.handling_fee,
          selectedOperator?.currency?.symbol || '',
        )}
      </PriceContainer>

      <PriceContainer>
        <PriceLabel>{t('molecules.LegPriceTooltip.cateringFee')}</PriceLabel>
        {getFormattedPrice(
          extras.catering_fee,
          selectedOperator?.currency?.symbol || '',
        )}
      </PriceContainer>

      <PriceContainer>
        <PriceLabel>{t('molecules.LegPriceTooltip.departureFee')}</PriceLabel>
        {getFormattedPrice(
          extras.departure_fee,
          selectedOperator?.currency?.symbol || '',
        )}
      </PriceContainer>

      <PriceContainer>
        <PriceLabel>{t('molecules.LegPriceTooltip.arrivalFee')}</PriceLabel>
        {getFormattedPrice(
          extras.arrival_fee,
          selectedOperator?.currency?.symbol || '',
        )}
      </PriceContainer>

      {Object.values(extras.other_costs).map((otherCost) => (
        <PriceContainer>
          <PriceLabel>{otherCost.label}:</PriceLabel>
          {getFormattedPrice(
            otherCost.value,
            selectedOperator?.currency?.symbol || '',
          )}
        </PriceContainer>
      ))}
    </Container>
  )
}

const Container = styled.div`
  font-size: 0.9rem;
  line-height: 1.2;
`

const PriceContainer = styled.div`
  &:not(:last-child) {
    padding-bottom: 0.25rem;
  }
`

const PriceLabel = styled.div`
  display: inline-block;
  width: 10rem;
`

export default LegPriceTooltip
