import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'

import { AircraftDetailDto } from '@shared/dto/aircraft.dto'
import { LegTypes } from '@shared/enums'
import { assertUnreachable } from '@shared/utils/assertUnreachable'
import { LegDetailDto } from '@shared/dto/requests.dto'

dayjs.extend(utc)
dayjs.extend(isBetween)

export const getLegTurnaroundTimeInMinutes = (
  legType: LegTypes,
  partialAircraft: Pick<
    AircraftDetailDto,
    | 'turnaround_before_empty_leg_in_minutes'
    | 'turnaround_before_occupied_leg_in_minutes'
  >,
) => {
  switch (legType) {
    case LegTypes.Outage:
      return 0

    case LegTypes.Occupied:
      return partialAircraft.turnaround_before_occupied_leg_in_minutes

    case LegTypes.Empty:
    case LegTypes.Ferry:
      return partialAircraft.turnaround_before_empty_leg_in_minutes

    case LegTypes.Removed:
      throw new Error(
        `Turnaround can't be calculated for leg type '${legType}'`,
      )

    default:
      assertUnreachable(legType)
  }
}

export const getEarliestLegDepartureDate = (
  partialLegs: Pick<LegDetailDto, 'departure_date'>[],
) => {
  return partialLegs.reduce<Date | string | null>((acc, leg) => {
    if (!acc || dayjs(leg.departure_date).isBefore(acc)) {
      return leg.departure_date
    }

    return acc
  }, null)
}

export const getLatestLegArrivalDate = (
  partialLegs: Pick<LegDetailDto, 'arrival_date'>[],
) => {
  return partialLegs.reduce<Date | string | null>((acc, leg) => {
    if (!acc || dayjs(leg.arrival_date).isAfter(acc)) {
      return leg.arrival_date
    }

    return acc
  }, null)
}

export const getIsPrecedingLegAirportDifferent = (
  sortedPartialLegs: Pick<
    LegDetailDto,
    'departure_airport_id' | 'arrival_airport_id'
  >[],
) => {
  return sortedPartialLegs.some((leg, index) => {
    const isFirstItem = index === 0

    return (
      !isFirstItem &&
      leg.departure_airport_id !==
        sortedPartialLegs[index - 1].arrival_airport_id
    )
  })
}

export const getIsSucceedingLegAirportDifferent = (
  sortedPartialLegs: Pick<
    LegDetailDto,
    'departure_airport_id' | 'arrival_airport_id'
  >[],
) => {
  return sortedPartialLegs.some((leg, index) => {
    const isLastItem = index === sortedPartialLegs.length - 1

    return (
      !isLastItem &&
      leg.arrival_airport_id !==
        sortedPartialLegs[index + 1].departure_airport_id
    )
  })
}
